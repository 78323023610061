import { t } from '@/i18n/translation_util';

export function PricingLinkABTest() {
  const href = `#checkout`;

  return (
    <>
      <a
        href={href}
        className="relative font-[500] leading-[24px] text-[#171717] no-underline lg:text-[16px]"
      >
        {t('Pricing')}
      </a>
    </>
  );
}
